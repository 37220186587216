import { register, init, locale } from "svelte-i18n"
import { get } from "svelte/store"
import { browser } from "$app/environment"

register("en", () => import("./translations/en.json"))
register("fr", () => import("./translations/fr.json"))

// Initialize with a default locale
init({
  fallbackLocale: "en",
  initialLocale: "en",
})

// Function to get the initial locale based on competition settings
export function getInitialLocale(competition?: { language?: string }) {
  if (competition?.language) {
    return competition.language
  }

  // Check localStorage for stored preference
  if (browser) {
    const storedLocale = localStorage.getItem("locale")
    if (storedLocale) {
      return storedLocale
    }
  }

  // Default to English
  return "en"
}

// Function to change locale
export function setLocale(localeCode: string) {
  locale.set(localeCode)
  if (browser) {
    localStorage.setItem("locale", localeCode)
  }
}
